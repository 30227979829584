export default function Apothekers() {
    return (
        <div className="bg-b text-white py-20">
            <div className="flex flex-col md:flex-row justify-center gap-5 px-10 items-center ">
                <img className="rounded-xl md:w-1/3" src="https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/apothekers.webp?alt=media&token=a61d6ddf-3a0d-42db-bca6-46de29160f09" alt="aptothekers"></img>
                <div className="prose text-white">
                    <h2 className="text-4xl text-white">Geverifieerd door apothekers</h2>
                    <p>Vertrouw op ervaren apothekers. Onze lijst van de 5 beste slaap supplement merken is samengesteld door deskundige apothekers, die hun expertise hebben ingezet om jouw de beste opties te bieden.</p>
                </div>
            </div>
            <p className="max-w-prose mx-auto text-center mt-10">Deze website wordt beheerd door Gimmy Products BV. Deze vergelijkingen werden intern doorgevoerd door de adviserende apothekers.  © 2023. All Rights Reserved. <a className="underline" href="https://gimmyvitamins.com/policies/privacy-policy">Privacyverklaring</a></p>
        </div>
    )
}