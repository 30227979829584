export default function Hero() {
    return (
        <section className="py-10">
            <div className="flex items-center justify-center flex-col-reverse md:flex-row px-10 gap-3">
                <div className="prose">
                    <h1>Een wetenschappelijke benadering van het onderzoek naar slaapmiddelen.</h1>
                    <p>Het doel van top5slaapmiddelen.nl is om bezoekers te voorzien van waardevolle informatie over de meest effectieve slaapmiddelen die beschikbaar zijn. Wij streven ernaar deze missie te volbrengen door middel van gedegen onderzoek, gebaseerd op een wetenschappelijke benadering. In deze tekst leggen we uit hoe we ons onderzoek hebben verricht en welke methodologieën we hebben gebruikt om tot onze bevindingen te komen.</p>
                </div>
                <img className="rounded-xl md:w-2/5" alt="top5slaapmiddelen" src="https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/jason-goodman-vbxyFxlgpjM-unsplash.webp?alt=media&token=a3d9f93a-5fb4-40d9-abd7-cd7c1796f25f"></img>

            </div>
        </section>
    )
}