

export default function Studie() {
    return (
        <div className="prose max-w-full mx-10">
            <div className="grid md:grid-cols-3 grid-cols-1">
                <h2 className="text-4xl col-span-1">Identificatie van pijlers</h2>
                <ul className="list-decimal max-w-5xl col-span-2 ">
                    Om de effectiviteit en veiligheid van slaapmiddelen te beoordelen, zijn meerdere factoren van belang. We hebben diverse pijlers vastgesteld die een rol spelen bij het evalueren van de kwaliteit en geschiktheid van slaapmiddelen. Onze pijlers omvatten:
                    <li><strong className="font-bold">Werkzaamheidsanalyse:</strong> We hebben de effectiviteit van diverse slaapmiddelen geanalyseerd om inzicht te krijgen in hoe goed ze werken in vergelijking met de claims van de fabrikant.</li>
                    <li><strong className="font-bold">Veiligheidsbeoordeling:</strong>We hebben de veiligheidsmaatregelen en bijwerkingen van de slaapmiddelen beoordeeld om de risico's voor gebruikers te begrijpen.</li>
                    <li><strong className="font-bold">Klantenbeoordelingen:</strong> We hebben klantenfeedback geanalyseerd om ervaringen van gebruikers met verschillende slaapmiddelen te verzamelen.</li>
                    <li><strong className="font-bold">Medisch Advies:</strong>We hebben overlegd met zorgprofessionals en slaapspecialisten om hun aanbevelingen en waarschuwingen over slaapmiddelen te integreren.</li>
                </ul>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1">
                <h2 className="text-4xl col-span-1">Methodologie</h2>
                <ul className=" list-decimal max-w-5xl col-span-2">
                We volgden een systematische aanpak in ons onderzoek door een uitgebreide database samen te stellen met informatie over verschillende slaapmiddelen die op de markt beschikbaar zijn. Deze hebben we geanalyseerd aan de hand van de bovengenoemde pijlers, met voor elke pijler specifieke criteria om de slaapmiddelen te beoordelen:
                    <li><strong className="font-bold">Werkzaamheidsanalyse:</strong> We evalueerden en vergeleken de effectiviteit van elk middel met wetenschappelijke literatuur over slaapverbetering.</li>
                    <li><strong className="font-bold">Veiligheidsbeoordeling:</strong>We onderzochten de veiligheidsprofielen, waaronder certificeringen, klinische studies en ingrediëntenbronnen.</li>
                    <li><strong className="font-bold">Klantenbeoordelingen:</strong> We verzamelden data uit betrouwbare bronnen om een beeld te krijgen van gebruikerstevredenheid.</li>
                    <li><strong className="font-bold">Medisch Advies:</strong>We integreerden inzichten van zorgprofessionals over welke middelen zij aanbevelen en wat de essentiële aspecten zijn om te overwegen bij het kiezen van een slaapmiddel.</li>
                </ul>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1">
                <h2 className="text-4xl col-span-1">Real-life testen</h2>
                <p className=" max-w-5xl col-span-2">
                We hebben onze bevindingen ondersteund met real-life testen door een selectie van slaapmiddelen uit te proberen. We hebben vrijwilligers met verschillende slaappatronen betrokken en hen verschillende slaapmiddelen laten gebruiken. Tijdens deze periode hebben we slaapkwaliteit, het gemak van inslapen en de algehele rust gevolgd. Deze observaties stelden ons in staat de middelen te vergelijken en de meest effectieve te identificeren.
                </p>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1">
                <h2 className="text-4xl col-span-1">Conclusie</h2>
                <p className="max-w-5xl col-span-2">
                Ons onderzoek op top5slaapmiddelen.nl is grondig en volgens gestandaardiseerde methodologie, waarbij diverse pijlers worden gebruikt om slaapmiddelen te beoordelen. Door een combinatie van werkzaamheidsanalyse, veiligheidsevaluatie, klantenfeedback en medisch advies hebben we een uitgebreid beeld gekregen van de beste slaapmiddelen op de markt. Onze bevindingen zijn versterkt door praktijktesten, waardoor we een betrouwbare bron zijn voor consumenten die op zoek zijn naar effectieve slaapmiddelen.
                </p>
            </div>
        </div>
    )
}