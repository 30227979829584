export default function Footer() {
    return (
        <div className="max-w-7xl mx-auto py-5">
            <div className=" flex flex-col sm:flex-row sm:justify-between mx-auto  border-b-2 border-black p-8 mb-10">
                <a href="/"><img className="" src="https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/top5slaapmiddelenlogo.png?alt=media&token=b74c576b-5453-4c38-bf28-7da728463739"></img></a>
                <div className="flex text-b flex-col gap-5 items-center">
                    <a className="underline" href="/criteria">Criteria</a>
                    <a className="underline" href="/faq">FAQ</a>
                </div>
            </div>
            <p className="bottom-0">© top5slaapmiddelen.nl 2024</p>
        </div>
    )
}