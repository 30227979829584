import { StarIcon } from '@heroicons/react/20/solid'

function getUTMParams() {
    const params = new URLSearchParams(window.location.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');
    return `utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
}

const products = [
   {
    name: "GIMMY Deep Sleep gummies",
    img: "https://gimmyvitamins.com/cdn/shop/files/Tekengebied1_16_0.75x_1296x.webp?v=1715958604",
    url: `https://gimmyvitamins.com/products/gimmy-slaap-vitamine-gummies?${getUTMParams()}`,
    rating: 4.8/5,
    reviews: 734,
    checks : [
        {
            positive: true,
            text: "Lekker in smaak en snelle werking"
        },
        {
            positive: true,
            text: "Dankzij gummy vorm betere opname in lichaam"
        },
        {
            positive: true,
            text: "100% natuurlijk, suikervrij en geen gewenning"
        },
    ],
    best: true
   },
   
   {
    name: "Lunestil",
    img: "https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/lunestil.webp?alt=media&token=9d9f0dd6-ffb3-4b55-a5ba-960aeeff388c",
    url: "https://www.newpharma.be/apotheek/eg/855854/lunestil-60-duocapsules.html",
    rating: 4.4/5,
    reviews: 621,
    checks : [
        {
            positive: true,
            text: "Duocapsule met vertraagde afgifte"
        },
        {
            positive: true,
            text: "Bevat krachtige en natuurlijke ingrediënten"
        },
        {
            positive: true,
            text: "Lavendel extract"
        },
    ],
    best: false
   },
   
   {
    name: "MetaSleep capsules",
    img: "https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/metasleep.webp?alt=media&token=e231d682-e87f-4244-bf09-8e640fdc4290",
    url: "https://www.efarma.nl/metagenics-metasleep-tablet-1mg/16331575",
    rating: 4.1/5,
    reviews: 384,
    checks : [
        {
            positive: true,
            text: "Complete formule"
        },
        {
            positive: true,
            text: "Eenvoudig in gebruik, 1 pil per dag"
        },
        {
            positive: true,
            text: "Geen afhankelijkheid"
        },
    ],
    best: false
   },
   
   {
    name: "Valdispert night time gummies",
    img: "https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/valdis.webp?alt=media&token=ebe96b87-73c9-46e5-9460-03e1b3d29997",
    url: "https://www.etos.nl/producten/valdispert-night-time-natural-gummies-45-stuks-120547941.html",
    rating: 3.7/5,
    reviews: 384,
    checks : [
        {
            positive: true,
            text: "Gummy vorm, plakken wel aan tanden en bevatten suiker"
        },
        {
            positive: true,
            text: "Natuurlijke stoffen"
        },
        {
            positive: true,
            text: "Gemakkelijk in te nemen"
        },
    ],
    best: false
   },
   
   {
    name: "Shiepz Nachtrust",
    img: "https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/schiepz.webp?alt=media&token=f4115e35-fdb7-4c24-8305-0e67bece9153",
    url: "https://www.plein.be/shiepz-gezonde-nachtrust-nachtrust-3-in-1-30-capsules",
    rating: 3.2/5,
    reviews: 168,
    checks : [
        {
            positive: true,
            text: "Geeft ontspanning"
        },
        {
            positive: true,
            text: "Vrij van smaakstoffen"
        },
        {
            positive: true,
            text: "Liquid capsules zijn gemakkelijker dan pillen"
        },
    ],
    best: false
   },
   {
    name: "Droomsap",
    img: "https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/droomsap.webp?alt=media&token=37921737-6be6-48c8-b7c7-3019cbb39544",
    url: "https://www.optimalegezondheid.com/droomsap/",
    rating: 2.6/5,
    reviews: 535,
    checks : [
        {
            positive: true,
            text: "Bevat plantenextracten-mengsel"
        },
        {
            positive: false,
            text: "Illegaal in Nederland wegens schadelijke stoffen"
        },
        {
            positive: false,
            text: "Veel klachten over ongewenste bijwerkingen"
        },
    ],
    best: null
   },
   
]


export default function List() {
    
    return (
        <section className="bg-b" id="slaap">
            
            <div className="items-center flex flex-col gap-10 py-10">
                {
                    products.map((item, index)=> {
                        const fractionRating = `${item.rating * 5}/5`;
                        const roundedRating = item.rating === 4.8/5 ? 5 : Math.floor(item.rating * 5);

                        return (
                            <>
                                {item.best === null ? <h2 className="text-2xl text-white text-center">Belangrijk te vermelden: <span className='font-bold'>Droomsap</span> slaapmiddel met verboden middelen</h2> : <></>}
                                <div className="rounded-xl bg-white md:p-3 px-3 py-5 relative mx-10">
                                    {item.best === true ?
                                    <div className='absolute -top-4 -left-4 bg-b text-white p-3 rounded-full border-white border-2'>
                                        👍 Beste uit de test
                                    </div> 
                                    : <></>}
                                    <div className="flex flex-col md:flex-row items-center justify-center max-w-md md:max-w-2xl gap-4 rounded-xl">
                                        <img className="w-1/2 md:w-1/4 rounded-xl" src={item.img}></img>
                                        <div className="md:w-1/2">
                                            <h2 className="font-bold text-2xl">{item.name}</h2>
                                            <div className="">
                                            <div className="flex items-center">
                                            
                                            {[...Array(5)].map((star, i) => (
                                                    <StarIcon
                                                        key={i}
                                                        className={`${i < roundedRating ? 'text-yellow-400' : 'text-gray-200'} h-5 w-5 flex-shrink-0`}
                                                        aria-hidden="true"
                                                    />
                                                ))}
                                            
                                            </div>
                                                <div className='space-x-3 flex'>
                                                    <p>{fractionRating}</p>
                                                    <p>({item.reviews} Reviews)</p>
                                                </div>
                                            </div>
                                            <div className='space-y-3 py-3'>

                                            {
                                                item.checks.map((check, index) => (
                                                    <div className="flex gap-1">
                                                        <p>{check.positive ? "✓" : "✕"}</p>
                                                        <p>{check.text}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        </div>
                                        <div className="md:w-1/4 justify-center items-center mx-auto text-center">
                                            {item.best === true ? 
                                            <p className='mb-4 text-center'>10% Korting met Code <span className='underline text-b'>TOP5</span></p> : <></>}
                                            <a href={item.url} className={`p-2 cursor-pointer mx-auto text-center rounded-full ${item.best === true ? "bg-b text-white hover:bg-white hover:text-b border-b border-2" : "border-b border-2 hover:bg-b hover:text-white text-b"}`}>{item.best === true ? "Ontdek de website" : "Ontdek hier"}</a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </section>
    )
}