import Accordian, { AccordianItem } from "./Accordian";

export default function FAQcomponent() {

    return (
        <>
        <div className='min-h-screen flex flex-col items-center justify-center'>
            <Accordian className="max-w-7xl">
            <AccordianItem value="1" trigger="Hoe weet ik welk slaapmiddel het beste is?">
                <div className="prose">
                    <p>Het kiezen van het beste slaapmiddel hangt af van factoren zoals je specifieke slaapproblemen, gezondheidstoestand, leeftijd en persoonlijke voorkeuren. Let op de actieve ingrediënten en kies voor producten met een bewezen werkzaamheid en zonder schadelijke toevoegingen. Zoek naar middelen die zijn ontworpen voor jouw specifieke slaapprobleem, zoals moeilijkheid met inslapen of doorslapen. Kies producten van betrouwbare fabrikanten die voldoen aan regelgeving en kwaliteitsstandaarden. Let goed op hoe je reageert op een slaapmiddel en pas de keuze indien nodig aan.</p>
                </div>
            </AccordianItem>
            <AccordianItem value="2" trigger="Welk slaapmiddel adviseren zorgprofessionals?">
                <div className="prose">
                    <p>Zorgprofessionals adviseren over het algemeen persoonlijk afgestemde slaapmiddelen die rekening houden met je specifieke slaapproblemen en gezondheidstoestand. Het is belangrijk om een middel te kiezen dat past bij jouw situatie. Voor specifiek advies op maat is het altijd het beste om een zorgprofessional of slaapspecialist te raadplegen.</p>
                </div>        
            </AccordianItem>
            <AccordianItem value="3" trigger="Wat is het meest effective slaapmiddel?">
                <div className="prose">
                    <p>Er is geen standaard antwoord op wat het meest effectieve slaapmiddel is, omdat de slaapbehoeften en reacties van personen sterk kunnen variëren. Echter, effectieve slaapmiddelen hebben gemeenschappelijk:</p>
                    <ul className="list-disc ml-4 my-2">
                        <li>Hoogwaardige ingrediënten: Een effectief slaapmiddel bevat veilige, bewezen actieve ingrediënten die helpen bij slaapproblemen zonder onnodige toevoegingen of schadelijke stoffen.</li>
                        <li>Wetenschappelijke ondersteuning en bewezen ingrediënten: Het middel heeft klinische studies en onderzoek die de werkzaamheid en veiligheid ervan ondersteunen.</li>
                        <li>Aanbevolen doseringen: Het houdt zich aan doseringsrichtlijnen die zijn vastgesteld door gezondheidsautoriteiten of medische organisaties.</li>
                        <li>Persoonlijke aanpassing: Het meest effectieve slaapmiddel is aangepast aan de individuele slaapproblemen en gezondheidsomstandigheden van de persoon, inclusief eventuele medicijninteracties of gezondheidsaandoeningen.</li>
                    </ul>
                    <p>Het is cruciaal te benadrukken dat wat effectief is voor de ene persoon, mogelijk niet hetzelfde effect heeft voor een ander. </p>
                </div>
            </AccordianItem>
            <AccordianItem value="4" trigger="Waar moet ik op letten bij slaapmiddelen?">
                <div className="prose">
                    <p>Bij het kiezen van slaapmiddelen zijn er verschillende belangrijke aspecten om in overweging te nemen om ervoor te zorgen dat je een veilig en effectief product kiest:</p>
                    <ul className="list-disc ml-4 my-2">
                        <li>Natuurlijke ingrediënten: Zoek naar slaapmiddelen die natuurlijke ingrediënten bevatten, zoals melatonine, valeriaan, en kamille, die bekend staan om hun slaapbevorderende eigenschappen. Natuurlijke ingrediënten worden vaak als veiliger beschouwd en hebben minder kans op bijwerkingen in vergelijking met synthetische alternatieven.</li>
                        <li>Geen gewenningsverschijnselen: Kies voor middelen die geen verslavingspotentieel hebben of gewenning veroorzaken, zodat je ze veilig kunt gebruiken zonder afhankelijkheid te ontwikkelen of te moeten verhogen in dosering over tijd.</li>
                        <li>Gemakkelijk in te nemen: De vorm van het slaapmiddel moet gemakkelijk te consumeren zijn, zoals tabletten, capsules, vloeistoffen of sprays, en het moet snel door het lichaam kunnen worden opgenomen voor een snelle werking.</li>
                        <li>Hoog gedoseerd: Hoewel een hoge dosering soms wenselijk kan zijn, is het essentieel om de aanbevolen doseringen niet te overschrijden en om een middel te kiezen dat een effectieve dosering biedt die past bij jouw behoeften.</li>
                        <li>Certificering en kwaliteitscontrole: Controleer of het slaapmiddel voldoet aan de industrienormen en of het is gecertificeerd door relevante gezondheidsautoriteiten. Dit garandeert dat het product is getest en voldoet aan kwaliteits- en veiligheidsnormen.</li>
                        <li>Interactie met andere medicijnen: Wees je bewust van eventuele interacties tussen het slaapmiddel en andere medicijnen die je gebruikt. Raadpleeg een zorgprofessional om mogelijke negatieve interacties te voorkomen.</li>
                        <li>Korte- en langetermijneffecten: Overweeg de effecten van het slaapmiddel op zowel de korte als de lange termijn. Sommige middelen zijn bedoeld voor incidenteel gebruik, terwijl andere geschikt kunnen zijn voor langduriger gebruik.</li>
                    </ul>
                    <p>Door aandacht te besteden aan deze factoren, kun je een geïnformeerde keuze maken die je slaapkwaliteit verbetert zonder ongewenste bijwerkingen of andere risico's.</p>
                </div>
            </AccordianItem>
            </Accordian>
        </div>
        </>
    )
}