import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home'
import Criteria from './pages/Criteria'
import FAQ from './pages/FAQ';
import ReactGA from "react-ga4";

ReactGA.initialize("G-LT916KMB76");

function App() {

  return (
    <div >
        <Router>
          <Navbar />
          <Suspense fallback={ <div className='h-screen w-screen bg-dd pt-40 text-7xl text-center mx-auto justify-center text-white flex items-center'>loading...</div>} >
            <Routes>
                <Route path='/' element={ <Home />}/>
                <Route path='/criteria' element={ <Criteria />}/>
                <Route path='/faq' element={ <FAQ />}/>
            </Routes>
          </Suspense>
          <Footer />
        </Router>
     
    </div>
  );
}

export default App;
