import Hero from '../Criteria/Hero';
import Studie from '../Criteria/Studie';


export default function Criteria() {
    return (
        <div className="">
            <Hero />
            <Studie />
        </div>
    )
}