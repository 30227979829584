export default function Navbar() {
    var toggled = false;
    function toggleHamburger() {
        var a = document.querySelector('#hamburger-menu')
        var b = document.querySelector('#menu03')
        a.classList.contains('hidden') ? a.classList.remove('hidden') : a.classList.add('hidden') ;
        b.classList.contains('active') ? b.classList.remove('active') : b.classList.add('active') ;
        toggled === false ? toggled = true : toggled = false
    }
    return (
        <div>
            <div className=" flex flex-col sm:flex-row justify-between items-center px-10">
                <div className="flex w-full sm:w-auto justify-between">
                    <a href="/"><img className="w-4/5 md:w-full" alt="logo" src="https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/top5slaapmiddelenlogo.png?alt=media&token=b74c576b-5453-4c38-bf28-7da728463739"></img></a>
                    <div id='menu03' onClick={toggleHamburger} className="menu-trigger active:border-none relative w-[25px] h-[22px] bg-none border-none appearance-none cursor-pointer transition-all duration-500 box-border my-auto inline-flex items-center text-sm rounded-lg sm:hidden focus:outline-none focus:ring-2 text-gray-400 focus:ring-gray-600">
                        <span className="bg-b inline-block transition-all duration-500 box-border absolute left-0 w-full h-[3px] rounded top-0"></span>
                        <span className="bg-b inline-block transition-all duration-500 box-border absolute left-0 w-full h-[3px] rounded top-[9.5px] "></span>
                        <span className="bg-b inline-block transition-all duration-500 box-border absolute left-0 w-full h-[3px] rounded bottom-0"></span>        
                    </div>
                </div>
                <div id='hamburger-menu' className="hidden sm:block w-full sm:w-auto">
                    <div className="flex flex-col sm:flex-row items-center gap-10 ">
                        <a className="" href="/criteria">Criteria</a>
                        <a className="" href="/faq">FAQ</a>
                    </div>
                </div>
            </div>
        </div>
    )
}