import Hero from '../Home/Hero';
import List from '../Home/List';
import Studie from '../Home/Studie';
import Apothekers from '../Home/Apothekers';

export default function Home() {
    return (
        <div className="">
            <Hero />
            <List />
            <Studie />
            <Apothekers />
        </div>
    )
}