export default function Hero() {
    return (
        <section className="py-10">
            <div className="flex items-center justify-center flex-col-reverse xl:flex-row px-10 gap-3">
                <div className="prose">
                    <h1>De TOP 5 beste slaap supplementen van 2024</h1>
                    <p>Ontdek onze nieuwe top 5 slaap supplementen voor 2024. Onze apothekers hebben deze lijst samengesteld volgens zorgvuldige criteria om ervoor te zorgen dat alleen de beste opties worden aanbevolen. Maak gebruik van onze expertise en kies voor het beste natuurlijke slaapmiddel voor een gezond en goede nachtrust.</p>
                    <a className="hover:text-b hover:bg-white border-b border-2 no-underline bg-b rounded-full text-white p-5 top04" href="#slaap">Ontdek de beste slaapmiddelen</a>
                </div>
                <img className="rounded-xl" alt="top5slaapmiddelen" src="https://firebasestorage.googleapis.com/v0/b/topvijfslaapmiddelen.appspot.com/o/top5slaap.webp?alt=media&token=8ed33fa8-4e0b-4840-aed1-f4b8756f56aa"></img>

            </div>
        </section>
    )
}