export default function Studie() {
    return (
        <div className="prose max-w-[80%] mx-auto py-20">
            <h2 className="text-center text-b text-4xl max-w-prose">Wij zijn experts in slaap supplementen en hebben verschillende merken op de markt geprobeerd en vergeleken.</h2>
            <div className="grid w-full grid-cols-1 md:grid-cols-3 prose-headings:text-b justify-center gap-10">
                <div>
                    <h3>1. Wat maakt een goed slaapmiddel?</h3>
                    <p>Veel factoren spelen mee, maar de voornaamste zijn de werkzaamheid, betrouwbaarheid en de natuurlijke aard van de actieve stoffen die ze bevatten.</p>
                </div>
                <div>
                    <h3>2. Wat is gezond voor je lichaam?</h3>
                    <p>Veel slaapmiddelen of sterkere slaap medicatie op voorschrift veroorzaken gewenning in je lichaam. Vaak komt dit door een te hoge dosering melatonine, die je lichaam zelf ook al aanmaakt. Neem je te veel in, dan kan je lichaam wennen en zelf niet meer voldoende produceren.</p>
                </div>
                <div>
                    <h3>3. Wat bepaald of een slaap supplement goed werkt?</h3>
                    <p>De opname is van cruciaal belang. Veel actieve stoffen die in een capsule of pil worden verwerkt worden simpelweg afgebroken in je maag, waardoor ze niet worden opgenomen in je bloed. Zuigtabletjes, druppels of gummies bieden hier een sterk voordeel. De werking hangt doorgaans van persoon tot persoon af.</p>
                </div>
            </div>
        </div>
    )
}